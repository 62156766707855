import React from "react";
import Seo from "../../components/seo";
import { Link } from "gatsby";
import Carousel from "../../components/layout/Carousel";
import useIsInViewport from "../../hooks/useIsInViewport";

/* START: images import */
import head_lukas from "../../img/about/head_lukas.jpg";
import head_peter from "../../img/about/head_peter.jpg";
import head_bob from "../../img/about/head_bob.jpg";
import deloittece from "../../img/partners/deloittece.svg";
import every_interaction from "../../img/partners/every_interaction.svg";
// import data_advance from '../img/partners/data-advance.png';
// import wai from '../img/partners/wai.png';
import and_agency from "../../img/partners/agency.svg";
// import lighthouse from "../../img/partners/lighthouse.svg";
import imao from "../../img/partners/imao.svg";
import open_culture from "../../img/partners/open_culture.svg";
import toman_design from "../../img/partners/toman.svg";
import milan from "../../img/partners/milann.svg";
import friendly from "../../img/partners/friendly.svg";
/* END: images import */

const carouselItems = [
    { src: deloittece, alt: "Deloitte CE", href: "http://www.deloittece.com" },
    { src: every_interaction, alt: "Every Interaction", href: "http://www.everyinteraction.com" },
    { src: toman_design, alt: "Toman Desing", href: "http://www.toman-design.com/cs" },
    { src: and_agency, alt: "& Agency", href: "http://andagency.co.uk" },
    { src: imao, alt: "Imao", href: "http://imao.cz" },
    { src: open_culture, alt: "Openculture", href: "https://www.opencultu.re" },
    { src: milan, alt: "Milan Nedved ", href: "https://milann.info" },
    { src: friendly, alt: "Friendly", href: "https://www.friendlydigital.cz" },
];

const WhoWeAre = ({ setObserverRef }) => (
    <article className="article" ref={setObserverRef} data-observer={1}>
        <h2 className="line sm animate-1">Co jsme zač?</h2>
        <p className="animate-2">
            Code 8 založili v roce 2012 Lukáš Vacovský a Petr Očenášek, dva bývalí kolegové, kteří se ještě v minulém století potkali v legendární{" "}
            <a href="https://www.bloomberg.com/research/stocks/private/snapshot.asp?privcapId=166961" target="_blank" rel="noopener noreferrer">
                Sambě
            </a>
            . Když se koncem roku 2011 vrátil Lukáš po sedmi letech v Anglii zpátky do Čech, Petr ve své práci cítil, že je čas na změnu. Frontend potkal backend v pravý čas a
            vznikla z toho firma s kompletním záběrem co se programování týče. V březnu 2020 posílil tým multitalentovaný Robert - univerzální voják digitálních bojišť. Start-up
            prostředím protřelý a komunikací s investory ostřílený chlapík otevřel v Code Eightu další dimenzi toho, co dokážeme poskytnout.
        </p>

        <h3 className="animate-3">Naše zkušenosti jsou teď vaše</h3>
        <p className="animate-4">
            V minulosti jsme oba pracovali jak na straně klientů, tak v agenturách. Chápeme, jak fungují různé týmy, a umíme se velmi dobře naladit na rozdílnou dynamiku jejich
            fungování a komunikace.{" "}
        </p>
    </article>
);

const Founders = ({ setObserverRef }) => (
    <article className="article founders animate-1" ref={setObserverRef} data-observer={2}>
        {/*<h3>Founders</h3>*/}
        <div className="animate-2">
            <img className="animation-up" src={head_lukas} alt="Lukas Vacovsky" />
            <h4 className="animate-3">Lukáš Vacovský</h4>
            <h5 className="animate-4">Frontend</h5>
            <p className="animate-5">Strávil 7 let v prostředí londýnských agentur. Dokáže být docela puntičkář ohledně kvality kódu, otevřeného internetu a pražských pizzerií.</p>
            <a className="animate-6" href="mailto:lukas@code8.cz">
                Kontaktujte Lukáše
            </a>
        </div>
        <div className="animate-2">
            <img className="animation-up" src={head_peter} alt="Petr Ocenasek" />
            <h4 className="animate-3">Petr Očenášek</h4>
            <h5 className="animate-4">Backend</h5>
            <p className="animate-5">
                "Bárt" už na počátkku milénia navrhoval databáze, kreslil UML diagramy, psal kód pro velké korporáty a programoval “hračky” na dálkové ovládání. Pizzu moc neřeší.
            </p>
            <a className="animate-6" href="mailto:petr@code8.cz">
                Kontaktujte Petra
            </a>
        </div>
        <div className="animate-2">
            <img className="animation-up" src={head_bob} alt="Robert Kaločai" />
            <h4 className="animate-3">Robert Kaločai</h4>
            <h5 className="animate-4">Produkt</h5>
            <p className="animate-5">
                Sbíral zkušenosti v kolébce startupového prostředí - San Franciscu. Se svým Nanits universe dobyl Everest digitálního komixu. A pomocí své vepřové pacičky dokáže
                přesvědčit Bárta téměř k čemukoli.
            </p>
            <a className="animate-5" href="mailto:robert@code8.cz">
                Kontaktujte Roberta
            </a>
        </div>
    </article>
);

const Experience = ({ setObserverRef }) => (
    <article className="article" id="experience" ref={setObserverRef} data-observer={3}>
        <div className="text">
            <h2 className="line animate-1">Naši partneři</h2>
            <p className="animate-2">
                Za dobu existence Code 8 jsme potkali několik super schopných lidí a firem, se kterými jsme navázali užší spolupráci. Milerádi je zde uvádíme jako naše partnery.
            </p>
        </div>
        <div className="animate-3" id="experienced">
            <Carousel items={carouselItems} />
        </div>
        <div className="clear"></div>
        <div className="text animate-4">
            <br />
            <br />
            <p>
                Máte-li zájem o spolupráci, <Link to="/cz/kontakt">kontaktuje nás</Link>
            </p>
        </div>
    </article>
);

const About = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <Seo title="O nás" lang="cs" />
            <div>
                <WhoWeAre setObserverRef={setRef} />
                <Founders setObserverRef={setRef} />
                <Experience setObserverRef={setRef} />
            </div>
            <div className="clear"></div>
        </>
    );
};

export default About;
